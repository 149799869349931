import React, { FC } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';

import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';
import { Marked } from 'components/Marked/Marked';

import useMedia from 'hooks/useMedia';
import Button from 'utils/types/Button';

import {
  StyledTextWrapper,
  StyledImage,
  StyledPhotos,
  StyledColumns,
  StyledTextColumn,
  StyledPhotosColumn,
  StyledButtonContainer,
  StyledLink
} from './OurArts.styles';

export type OurArtsProps = {
  title: string;
  description: MDXRemoteSerializeResult;
  button: Button;
  images: Array<{
    id: number;
    src: string;
    alt: string;
    path: string;
  }>;
};

export const OurArts: FC<OurArtsProps> = ({ title, description, button, images }) => {
  const { isMobile } = useMedia();
  const maxPerSide = 2;
  const leftImages = images.slice(0, maxPerSide);
  const rightImages = images.slice(maxPerSide, maxPerSide * 2);

  return (
    <Container>
      <StyledColumns>
        <StyledPhotosColumn>
          <StyledPhotos justifyContent="flex-end">
            {leftImages.map(({ id, path, alt, src }) => (
              <StyledLink key={id} href={path} title="dribbble project">
                <StyledImage src={`${src}.jpg`} alt={alt} width={263} height={264} quality={100} />
              </StyledLink>
            ))}
          </StyledPhotos>
          {!isMobile && (
            <StyledPhotos margin="48px 0 0" justifyContent="flex-start">
              {rightImages.map(({ id, path, alt, src }) => (
                <StyledLink key={id} href={path} title="dribbble project">
                  <StyledImage src={`${src}.jpg`} alt={alt} width="263px" height="264px" quality={100} />
                </StyledLink>
              ))}
            </StyledPhotos>
          )}
        </StyledPhotosColumn>
        <StyledTextColumn>
          <Typography variant="h1" renderAs="h3">
            {title}
          </Typography>
          <StyledTextWrapper>
            <Typography variant="body1">
              <Marked>{description}</Marked>
            </Typography>
          </StyledTextWrapper>
          <StyledButtonContainer>
            <LinkedButton variant="primary" width="262px" href={button.href}>
              {button.buttonCopy}
            </LinkedButton>
          </StyledButtonContainer>
        </StyledTextColumn>
      </StyledColumns>
    </Container>
  );
};
